<template>
  <div>
    <GmapMap style="width: 90%; height: 60vh; margin:auto;" :center="{lat:currentLocation.latitude, lng:currentLocation.longitude}" :zoom="17" >
  
      <GmapMarker
        label="★"
        :position="{
          lat: this.currentLocation.latitude,
          lng: this.currentLocation.longitude,
        }"
        />
    </GmapMap>

    <div class="row" style="width: 90%; margin:auto;">
    	<div class="col-lg-6" @click="geolocation">
    		<button class="btn btn-danger btn-lg mt-4" style="width: 100%">
		      <i class="fas fa-map-marker"></i> تحديد الموقع
		    </button>
    	</div>
    	<div class="col-lg-6" @click="send">
    		<button class="btn btn-success btn-lg mt-4" style="width: 100%">
		      <i class="fas fa-check"></i> إرسال
		    </button>
    	</div>
    </div>
    <div 
    	v-if="status" 
    	class="row text-center d-inline-block mt-2 mx-auto" 
    	style="width: 90%;">
			<p class="text-success ">{{ status }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ShareLocation',

  data() {
    return {
      markers: [],
      status: "",
      currentLocation : { latitude : 0, longitude : 0},
      order_id: this.getOrderID(),
    }
  },
  mounted ()  {
  	this.geolocation();
  },
  methods: {
  	getOrderID() {
  		let url = new URL(window.location.href);
		let order_id = url.searchParams.get("order_id");

		return order_id;
  	},
    setDescription(description) {
      this.description = description;
    },
    setPlace(place) {
      this.place = place
    },
    usePlace() {
      if (this.place) {
        this.markers.push({
          position: {
            lat: this.place.geometry.location.lat(),
            lng: this.place.geometry.location.lng(),
          }
        })
        this.place = null;
      }
    },
    geolocation() {
      navigator.geolocation.getCurrentPosition((position) => {
        this.currentLocation = {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude
        };
      });
    },
    send() {
      this.$store.dispatch('orders/sendLocation', {order_id: this.order_id, location: this.currentLocation})
      		.then(response => {
        		 console.log(response) 
        		 this.status = "!تم الارسال بنجاح";
      		})
      		.catch(error => { console.log(error) });
    }
  }
}
</script>

<style lang="css" scoped>
	* {
		font-size: 2rem;
	}
</style>
